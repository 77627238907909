import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';

export default function RbcJumbotron() {
    return (
        <section className="container-fullwidth wwb">
            <Jumbotron>
                <div className="jumbotron-content">
                <h1>What We believe</h1>
                </div>
            </Jumbotron>
        </section>
    );
}